const handleSubmit = (event) => {
  event.preventDefault();

  const myForm = event.target;
  const formData = new FormData(myForm);

  fetch("https://formbold.com/s/9mZGj", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formData).toString(),
  })
    .then(() => console.log("Form successfully submitted"))
    .catch((error) => alert(error));

    document.getElementById("contact").value = "";
    var formContainer = document.getElementsByClassName('form')[0];

    // This selects the first form element within the previously selected 'form' container
    var form = formContainer.getElementsByTagName('form')[0];

    // This sets the display style of the selected form to 'none'
    form.style.visibility = 'hidden';
    document.getElementById('subscribe').innerHTML = 'We\'ll stay in touch';
};

function adjustViewportHeight() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

// Call the function initially and on resize events
adjustViewportHeight();
window.addEventListener('resize', adjustViewportHeight);

document
  .querySelector("form")
  .addEventListener("submit", handleSubmit);

