import * as THREE from 'three'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

const scene = new THREE.Scene()
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 100)
const renderer = new THREE.WebGLRenderer()
const controls = new OrbitControls(camera, renderer.domElement)
const radius = 4
let videoWrapper = document.getElementById('video-background')

controls.enablePan = false
controls.enableZoom = false
controls.enableDamping = true


let spherical = new THREE.Spherical(1, 1.9, 3.5)
spherical.makeSafe()
camera.position.setFromSpherical(spherical)


const video = document.createElement('video')

const videoSrc = document.createElement('source');
videoSrc.src = 'https://stream.mux.com/DFT1kiagxKwPrk94v9Vz4hvpdasr78xbroeaD02UengE.m3u8';
videoSrc.type = 'application/x-mpegURL';

if (video.canPlayType('application/vnd.apple.mpegurl')) {
  video.appendChild(videoSrc);
} else if (Hls.isSupported()) {
  var hls = new Hls();
  hls.loadSource(videoSrc.src);
  hls.attachMedia(video);
}

video.loop = true
video.muted = true
video.playsInline = true
// video.autoplay = true
video.crossOrigin = 'anonymous'
video.play()

renderer.setSize(window.innerWidth, window.innerHeight)
renderer.outputEncoding = THREE.sRGBEncoding

videoWrapper.appendChild(renderer.domElement)

const texture = new THREE.VideoTexture(video)
texture.colorSpace = THREE.SRGBColorSpace
texture.wrapS = THREE.RepeatWrapping
texture.repeat.x = - 1
texture.offset.x = 1
const geometry = new THREE.SphereGeometry(radius, 48, 32)
const material = new THREE.MeshBasicMaterial({ map: texture })
material.side = THREE.BackSide

const sphere = new THREE.Mesh(geometry, material)
scene.add(sphere)

function animate() {
    requestAnimationFrame(animate)
    controls.update();
    renderer.render(scene, camera)
  }
  
  animate()

window.addEventListener('resize', onWindowResize)

function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight
    camera.updateProjectionMatrix()

    renderer.setSize(window.innerWidth, window.innerHeight)
    getComputedStyle(document.documentElement).getPropertyValue("--sat")
}